import Vue from "vue";
import Vuex from "vuex";
import { createLogger } from "vuex";

import modules from "@/_evoli/store/modules";
import mutations from "@/_evoli/store/mutations";
import actions from "@/_evoli/store/actions";
import getters from "@/_evoli/store/getters";

import filterHeaders from "@/_evoli/helper/filterHeaders";
import filterStatus from "@/_evoli/helper/filterStatus";
import permission from "@/_evoli/helper/permission.js";

import router from "@/_evoli/router/index";

import { socketPlugin, getSocketDefaultState } from "@/_evoli/store/socket/socket.js";
import { getAuthDefaultState } from "@/_evoli/store/auth/auth.js";

Vue.use(Vuex);

function getDefaultState() {
  return {
    serviceNotAvailable: false,
    lastActivity: 0,
    loading: {
      GET: [],
      POST: [],
      PATCH: [],
      DELETE: [],
      SOCKET: []
    },
    skeleton: {
      GET: [],
      POST: [],
      PATCH: [],
      DELETE: [],
      SOCKET: []
    },
    progress: {},
    deleteSearch: localStorage.getItem("deleteSearch")?.split(",").filter((e) => e.trim() !== "") || [],
    motd: {},
    navigation: false,
    filterList: {
      ticketsList: {
        processed: false
      }
    },
    dialog: "",
    minimized: null,
    textBlocksDrawer: {
      show: false,
      key: "",
      label: "",
      callback: null,
    },
    correctionDialog: {
      form: "",
      key: "",
    },
    fixedOpinionTitle: localStorage.getItem("fixedOpinionTitle") === "false" ? false : true,
    lists: [],
    opinionRedirectOverview: localStorage.getItem("opinionRedirectOverview") === "false" ? false : true,
    outsideMenu: {
      shortOpinionsToolbar: localStorage.getItem("outsideMenu.shortOpinionsToolbar")?.split(",").filter((e) => e.trim() !== "") || [],
      secondOpinionsToolbar: localStorage.getItem("outsideMenu.secondOpinionsToolbar")?.split(",").filter((e) => e.trim() !== "") || [],
      goaesToolbar: localStorage.getItem("outsideMenu.goaesToolbar")?.split(",").filter((e) => e.trim() !== "") || [],
    },
    filterStatus,
    filterHeaders
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    ...mutations,
    resetState(state) {
      Object.assign(state, getDefaultState())
      Vue.set(state, "socket", getSocketDefaultState())
      Vue.set(state, "auth", getAuthDefaultState())
      Vue.set(state, "entities", {})
    }
  },
  actions,
  modules,
  getters,
  plugins: [
    createLogger({
      collapsed: false,
      logMutations: false,
      actionTransformer(action) {
        return {
          type: action.type,
          ...action.payload,
        };
      },
    }),
    socketPlugin(),
    lastActivityPlugin()
  ],
})

function lastActivityPlugin() {
  return store => {
    store.commit("setLastActivity", new Date().getTime())
    
    let activ = true
    let events = ['mousedown', 'keydown','scroll', 'touchstart']

    events.forEach(eventName => {
      document.addEventListener(eventName, () => {
        store.commit("setLastActivity", new Date().getTime())

        if(permission(store, ["EXPERT"]) && router.currentRoute.meta.entityName === "shortOpinions" && !activ){
          store.dispatch("socket/SEND_activ", {
            value: true,
            route: router.currentRoute.path
          })
          activ = true
        }

      })
    })

    let interval = setInterval(() => {
      if(permission(store, ["EXPERT"])){
        if(
          router.currentRoute.meta.entityName === "shortOpinions" &&
          new Date().getTime() - store.getters.getLastActivity > 60000 && 
          activ
        ){
          store.dispatch("socket/SEND_activ", {
            value: false,
            route: router.currentRoute.path
          })
          activ = false
        }
      } else {
        clearInterval(interval)
      }
    }, 1000);
    

  }
}
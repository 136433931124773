import endpoint from "@/_evoli/helper/endpoint.js";

export default {
  READ_Motd(state) {
    return endpoint(state, "GET", "/v1/motds/current", { siteType: "backoffice" }).then(
      (response) => {
        state.commit("setMotd", response);
      }
    ).catch(() => {
      // state.commit("setMotd", {
      //   content: "Aktuell finden Wartungsarbeiten statt. Vielen Dank für Ihr Verständnis.",
      //   type: "error",
      //   isActive: true
      // });
    })
  },
  BLOCK_User(store, payload) {
    return endpoint(
      store,
      "PATCH",
      "/v1/users/" + payload._id + "/block"
    ).then(() =>
      store.commit("snackbar/open", {
        content: "Geblockt",
        color: "error",
        icon: "mdi-check",
      })
    );
  },
  UNBLOCK_User(store, payload) {
    return endpoint(
      store,
      "PATCH",
      "/v1/users/" + payload._id + "/unblock"
    ).then(() =>
      store.commit("snackbar/open", {
        content: "Freigeschaltet",
        color: "success",
        icon: "mdi-check",
      })
    );
  },
  SEND_Email(store, payload) {
    return endpoint(
      store,
      "POST",
      payload.fax ? "/v1/faxnotifications" : "/v1/emailnotifications",
      null,
      payload,
      false
    ).then(() => {
      store.commit("snackbar/open", {
        content: "Gesendet",
        color: "success",
        icon: "mdi-check",
      })
      return true
    })
    .catch((res) => {
      let content = ""
      let str = payload.fax ? "Fax" : "E-Mail"
      switch (res.message) {
        case "EmailQuota reached":
          content = "Sie haben ihr tägliches Pensum an E-Mails erreicht."
          break;
        case "Attachments to large":
          content = "Der angefügte Anhang ist zu groß. Bitte ändern Sie Ihre Auswahl."
          break;
        default:
          content = str + " konnte nicht gesendet werden. Versuchen Sie es später erneut."
      }
      store.commit("snackbar/open", {
        content,
        color: "error",
        icon: "mdi-check",
      })
      return false
    });
  },
  BEDROCK_Correction(store, payload) {
    return endpoint(
      store,
      "POST",
      "/v1/bedrock/correction",
      null,
      payload,
      false
    )
  },
};
